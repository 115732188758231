import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, tap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class RedirectInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          console.log('event ', event);
          const redirectLocation = event.headers.get('X-Redirect-Location');
          console.log('redirect loca ', redirectLocation);
          /*  if (redirectLocation) {
            this.router.navigateByUrl(redirectLocation);
          }*/
        }
      }),
    );
  }
}
/*  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        console.log('Event !! ', event);
        if (event instanceof HttpResponse && event.status === 301) {
          const location = event.headers.get('Location');
          if (location) {
            this.router.navigateByUrl(location);
          }
        }
      }),
      catchError((error: HttpErrorResponse) => {
        console.log('Error !! ', error);
        if (error.status === 301 && error.headers.get('Location')) {
          const location = error.headers.get('Location');
          if (location) {
            this.router.navigateByUrl(location);
          }
        }
        return throwError(error);
      }),
    );
  }
}*/
/*
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    console.log('intercept');
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        if (error.status === 301 || error.status === 302) {
          const redirectUrl =
            error?.error?.location || error.headers.get('Location');
          console.log({ redirectUrl });
          if (redirectUrl) {
            window.location.href = redirectUrl;
            //return this.router.navigateByUrl(redirectUrl);
          }
        }
        return throwError(error);
      }),
    );
  }
}
*/
